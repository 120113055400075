import React from 'react';
import './Header.css'
import Mountain1 from '../../Backgrounds/mountain1.jpg'
import Mountain2 from '../../Backgrounds/mountain2.jpg'
import Mountain3 from '../../Backgrounds/mountain3.jpg'

const Header = () => {
    return (
        <div className="header">
            <h1 className="header-title">Tiffany's Travel</h1>
            <div className="header-photos">
                <img src={Mountain1} alt="mountain 1"/>
                <img src={Mountain2} alt="mountain 2"/>
                <img src={Mountain3} alt="mountain 3"/>
            </div>
        </div>
    )
}

export default Header;
