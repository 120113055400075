import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './Post.css';
import Carousel from '../Carousel/Carousel'; // Import the Carousel component
import { Link } from 'react-router-dom';
import EditIcon from '../../Icons/edit.svg';
import HomeIcon from '../../Icons/home.svg';

const Post = () => {
    const { postId } = useParams(); 
    const [blogData, setBlogData] = useState({
        title: '',
        blog: '',
        location: '',
        date: '',
        photos: []
    });

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const docRef = doc(db, 'posts', postId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const postData = docSnap.data();
                    setBlogData({
                        title: postData.title,
                        blog: postData.blog,
                        location: postData.location,
                        date: postData.date,
                        photos: postData.photos
                    });
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching document: ', error);
            }
        };
        fetchPost();
    }, [postId]);

    return (
        <div className="post-container">
            <Link to={`/`} className="home-button"><img src={HomeIcon} alt="Home"/></Link>
            {blogData.photos.length === 1 ? (
                <div className="blog-photo">
                    <img src={blogData.photos[0]} alt="blogPhotos" />
                </div>
            ) : blogData.photos.length > 1 ? (
                <div className="blog-photos-carousel">
                    <Carousel photos={blogData.photos} />
                </div>
            ) : null}
            {/* Title, location, date, blog */}
            <div className="blog-text">
                <div className="blog-header">
                    <div className="blog-title">{blogData.title}</div>
                    <Link to={`/update-post/${postId}`} className="update-button">
                        <img src={EditIcon} alt="Edit Icon" className="edit-icon"/>
                    </Link>
                </div>
                <div className="blog-header blog-details">
                    <div className="blog-location">{blogData.location}</div>
                    <div className="blog-date">{blogData.date}</div>
                </div>
                <pre style={{ whiteSpace: 'pre-wrap' }}><div className="blog-post">{blogData.blog}</div></pre>
            </div>
        </div>
    );
};

export default Post;