import { Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import CreatePost from './components/CreatePost/CreatePost';
import Header from './components/Header/Header';
import HomePage from './components/HomePage/HomePage';
import Login from './components/Login/Login';
import UpdatePost from './components/UpdatePost/UpdatePost';
import Post from './components/Post/Post';

const App = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    return (
        <div style={{ overflowY: 'scroll', scrollbarWidth: 'none', height: '100vh' }}> {/* Hide scrollbar */}
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<><Header /><HomePage /></>} />
                <Route path="/:postId" element={<Post />} />
                {loading ? null : (
                    user ? (
                        <>
                            <Route path="/create-post" element={<CreatePost />} />
                            <Route path="/update-post/:postId" element={<UpdatePost />} />
                        </>
                    ) : (
                        <>
                            <Route path="/create-post" element={<Login />} />
                            <Route path="/update-post/:postId" element={<Login />} />
                        </>
                    )
                )}
            </Routes>
        </div>
    );
};

export default App;
