import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import './Login.css'; // Import the CSS file

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email, password);
            // User successfully signed in
            console.log('User signed in');
        } catch (error) {
            // Handle errors
            console.error('Error signing in:', error);
        }
    };

    return (
        <div className="login-form-container"> {/* Apply the login-form-container class */}
            <div className="login-my-form"> {/* Apply the login-my-form class */}
                <h2>Login</h2>
                <div className="login-form-group"> {/* Apply the login-form-group class */}
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                    />
                </div>
                <div className="login-form-group"> {/* Apply the login-form-group class */}
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                </div>
                <button onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
};

export default Login;
