import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCOCtpje93FUeYh1OxH0LhF5GUcHt44UC8",
    authDomain: "tiffanys-blog.firebaseapp.com",
    projectId: "tiffanys-blog",
    storageBucket: "tiffanys-blog.appspot.com",
    messagingSenderId: "1041430367609",
    appId: "1:1041430367609:web:d1d95fd913f0599f0373a8"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);