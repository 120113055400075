import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './UpdatePost.css';
import { Link } from 'react-router-dom';
import HomeIcon from '../../Icons/home.svg';
import BackIcon from '../../Icons/back.svg';

const UpdatePost = () => {
    const { postId } = useParams();
    const [blogData, setBlogData] = useState({
        title: '',
        blog: '',
        location: '',
        date: '',
        photos: []
    });
    const [imagePreview, setImagePreview] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const docRef = doc(db, 'posts', postId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const postData = docSnap.data();
                    setBlogData({
                        title: postData.title,
                        blog: postData.blog,
                        location: postData.location,
                        date: postData.date,
                        photos: postData.photos
                    });
                    setImagePreview(postData.photos.map(photo => ({ url: photo, isNew: false })));
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching document: ', error);
            }
        };
        fetchPost();
    }, [postId]);

    const handleFileChange = (e) => {
        const files = e.target.files;
        setSelectedFiles(files);
        const newPreviews = Array.from(files).map((file, index) => ({
            url: URL.createObjectURL(file),
            isNew: true
        }));
        setImagePreview([...imagePreview, ...newPreviews]);
    };

    const handleChange = (e) => {
        if (e.target.name === 'photos') {
            setSelectedFiles(e.target.files);
            const newPreviews = Array.from(e.target.files).map((file, index) => ({
                url: URL.createObjectURL(file),
                name: `Image ${index}`
            }));
            setImagePreview([...imagePreview, ...newPreviews]);
        } else {
            setBlogData({
                ...blogData,
                [e.target.name]: e.target.value
            });
        }
    };       
    
    const handlePhotoUpload = async (files) => {
        const storage = getStorage();
        const photoURLs = [];
        try {
            for (const file of files) {
                const storageRef = ref(storage, `images/${file.name}`);
                await uploadBytes(storageRef, file);
                const photoURL = await getDownloadURL(storageRef);
                photoURLs.push(photoURL);
            }
        } catch (error) {
            console.error('Error uploading photos:', error);
        }
        return photoURLs;
    };

    const handleRemovePhoto = (index) => {
        const updatedPreview = [...imagePreview];
        updatedPreview.splice(index, 1);
        setImagePreview(updatedPreview);

        if (index < blogData.photos.length) {
            const updatedBlogData = { ...blogData };
            updatedBlogData.photos.splice(index, 1);
            setBlogData(updatedBlogData);
        } else {
            const updatedFiles = [...selectedFiles];
            updatedFiles.splice(index - blogData.photos.length, 1);
            setSelectedFiles(updatedFiles);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const newPhotoURLs = await handlePhotoUpload(selectedFiles);
            const updatedPhotos = [...blogData.photos, ...newPhotoURLs];

            await updateDoc(doc(db, 'posts', postId), {
                title: blogData.title,
                blog: blogData.blog,
                location: blogData.location,
                photos: updatedPhotos
            });

            console.log("Post updated successfully");
            window.location.href = `/${postId}`;
        } catch (error) {
            console.error("Error updating post: ", error);
        }
    };

    return (
        <div className="form-container">
            <form className="my-form" onSubmit={handleSubmit}>
                <div className="update-header">
                    <div className=""><Link to={`/${postId}`} className=""><img src={BackIcon} alt="Back"/></Link></div>
                    <h2>Update Post</h2>
                    <div className="update-home-button"><Link to={`/`} className=""><img src={HomeIcon} alt="Home"/></Link></div>
                </div>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={blogData.title}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="blog">Blog</label>
                    <textarea
                        id="blog"
                        name="blog"
                        rows="15"
                        value={blogData.blog}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={blogData.location}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="photos">Photos</label>
                    <input
                        type="file"
                        accept="image/*"
                        id="photos"
                        name="photos"
                        multiple
                        onChange={handleFileChange}
                    />
                </div>
                <div className="image-preview">
                    {imagePreview.map((image, index) => (
                        <div key={index} className="update-image-container">
                            <img src={image.url} alt={`Image ${index}`} />
                            <button type="button" onClick={() => handleRemovePhoto(index)}></button>
                        </div>
                    ))}
                </div>
                <button type="submit">Update</button>
            </form>
        </div>
    );
};

export default UpdatePost