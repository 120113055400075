import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import './HomePage.css';
import AddIcon from '../../Icons/add.svg';

const HomePage = () => {
    const [posts, setPosts] = useState([]);
    const [maxCharsPerPost, setMaxCharsPerPost] = useState(1200); // Default maximum characters per post

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const screenHeight = window.screen.height;
            console.log(screenWidth);
            console.log(screenHeight);
            if (screenWidth <= 768) {
                setMaxCharsPerPost(600); // For smaller screens, allow fewer characters
            } else if (screenWidth >= 2560 && screenHeight >= 1440) {
                setMaxCharsPerPost(2650); // For 27" 1440p monitors, allow more characters
            } else {
                setMaxCharsPerPost(1200); // For other screens, allow the default number of characters
            }
    
            // Fetch random posts after adjusting screen size
            fetchposts();
        };
    
        handleResize(); // Call the function initially to set the correct maxCharsPerPost value
        window.addEventListener('resize', handleResize); // Listen for resize events
    
        // Clean up event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const truncatePost = (post, maxChars) => {
        if (post.blog.length <= maxChars) {
            return post.blog;
        } else {
            return post.blog.substring(0, maxChars) + "...";
        }
    };

    const fetchposts = async () => {
        const postsWithPhotos = [];
        const querySnapshot = await getDocs(collection(db, "posts"));
        querySnapshot.forEach((doc) => {
            const post = doc.data();
            if (post.photos && post.photos.length > 0) {
                postsWithPhotos.push({
                    id: doc.id,
                    ...post,
                    photo: post.photos[0],
                    date: post.date,
                    truncatedBlog: truncatePost(post, maxCharsPerPost)
                });
            }
        });
        // Sort posts by date in ascending order (oldest posts first)
        postsWithPhotos.sort((a, b) => {
            // Convert date strings to JavaScript Date objects
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            // Compare the dates
            if (dateB < dateA) {
                return -1;
            } else if (dateB > dateA) {
                return 1;
            } else {
                return 0;
            }
        });
        setPosts(postsWithPhotos);
    };

    return (
        <div className="container">
            <div className="create-post-container">
                <Link to={`/create-post`} className="create-post-link">
                    <div className="create-post-icon-container">
                        <img src={AddIcon} alt="Create Post" className="create-post-icon" />
                    </div>
                </Link>
            </div>
            {posts.map((post, index) => (
                <div className={index % 2 === 0 ? "home-post-container left-post" : "home-post-container right-post"} key={post.id}>
                    {index % 2 === 0 ? (
                        <>
                            <div className="photo-container left-photo">
                                <img className="photo" src={post.photo} alt="Post" />
                            </div>
                            <div className="text-container right-text">
                                <Link to={`/${post.id}`} style={{ color: 'inherit' }}><div className="left-post-title">{post.title}</div></Link>
                                <div className="heading">
                                    <div className="location">{post.location && <>{post.location} </>}</div>
                                    <div className="date">{post.date}</div>
                                </div>
                                <pre style={{ whiteSpace: 'pre-wrap' }}><div className="home-blog">{post.truncatedBlog}</div></pre>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="text-container left-text">
                                <Link to={`/${post.id}`} style={{ color: 'inherit' }}><div className="right-post-title">{post.title}</div></Link>
                                <div className="heading">
                                    <div className="location">{post.location && <>{post.location} </>}</div>
                                    <div className="date">{post.date}</div>
                                </div>
                                <pre style={{ whiteSpace: 'pre-wrap' }}><div className="home-blog">{post.truncatedBlog}</div></pre>
                            </div>
                            <div className="photo-container right-photo">
                                <img className="photo" src={post.photo} alt="Post" />
                            </div>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default HomePage;
