import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './CreatePost.css';
import { Link } from 'react-router-dom';
import HomeIcon from '../../Icons/home.svg';

const CreatePost = () => {
    const [blogData, setBlogData] = useState({
        title: '',
        blog: '',
        location: '',
        date: serverTimestamp(),
        photos: []
    });

    const [imagePreview, setImagePreview] = useState([]);

    const handleChange = (e) => {
        if (e.target.name === 'photos') {
            const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
            setImagePreview(filesArray);
        } else {
            setBlogData({
                ...blogData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handlePhotoUpload = async (files) => {
        const storage = getStorage();
        const photoURLs = [];
        for (const file of files) {
            const storageRef = ref(storage, file.name);
            await uploadBytes(storageRef, file);
            const photoURL = await getDownloadURL(storageRef);
            photoURLs.push(photoURL);
        }
        return photoURLs;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const photoURLs = await handlePhotoUpload(e.target.photos.files);
            
            // Format the date
            const dateObject = new Date(blogData.date);
            const formattedDate = dateObject.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            });
    
            const docRef = await addDoc(collection(db, 'posts'), {
                title: blogData.title,
                blog: blogData.blog,
                location: blogData.location,
                date: formattedDate, // Store the formatted date
                photos: photoURLs // Include photo URLs in Firestore
            });
            console.log("Blog created with ID: ", docRef.id);
    
            // Clear both blogData and imagePreview
            setBlogData({
                title: '',
                blog: '',
                location: '',
                date: serverTimestamp(),
                photos: [] // Clear uploaded photos
            });
    
            setImagePreview([]); // Clear image preview
            
            e.target.reset();
            window.location.href = `/${docRef.id}`;
        } catch (error) {
            console.error("Error creating blog: ", error);
        }
    };    

    return (
        <div className="form-container">
            <form className="my-form" onSubmit={handleSubmit}>
                <div className="update-header">
                    <h2>Create Post</h2>
                    <div className="update-home-button"><Link to={`/`} className=""><img src={HomeIcon} alt="Home"/></Link></div>
                </div>
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={blogData.title}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="blog">Blog</label>
                    <textarea
                        id="blog"
                        name="blog"
                        rows="15"
                        value={blogData.blog}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={blogData.location}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="date">Date</label>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        value={blogData.date}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="photos">Photos</label>
                    <input
                        type="file"
                        accept="image/*"
                        id="photos"
                        name="photos"
                        multiple
                        onChange={handleChange}
                    />
                </div>
                <div className="image-preview">
                    {imagePreview.map((image, index) => (
                        <img key={index} src={image} alt={`Image ${index}`} />
                    ))}
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default CreatePost;