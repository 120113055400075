import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Keyboard } from 'swiper/modules';

import './Carousel.css'; // You can create your own CSS file for additional styling

const Carousel = ({ photos }) => {
    return (
        <Swiper
            cssMode={true}
            navigation={true}
            keyboard={true}
            modules={[Navigation, Keyboard]}
            className="mySwiper"
        >
            {photos.map((photo, index) => (
                <SwiperSlide key={index} className="slide-container">
                    <div className="image-container">
                        <img src={photo} alt={`slide-${index}`} className="carousel-photo" />
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Carousel;

